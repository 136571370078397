// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/Blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/BlogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/Contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-imprint-js": () => import("./../../../src/templates/Imprint.js" /* webpackChunkName: "component---src-templates-imprint-js" */),
  "component---src-templates-landing-js": () => import("./../../../src/templates/Landing.js" /* webpackChunkName: "component---src-templates-landing-js" */),
  "component---src-templates-privacy-js": () => import("./../../../src/templates/Privacy.js" /* webpackChunkName: "component---src-templates-privacy-js" */),
  "component---src-templates-what-we-do-js": () => import("./../../../src/templates/WhatWeDo.js" /* webpackChunkName: "component---src-templates-what-we-do-js" */)
}

